import React, { useState } from "react";
import styles from "./ModalTable.module.scss";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { editPlayer } from "../../Redux/SearchingSection/SearchingSection.actions";
import InputErrorModal from "../InputErrorModal/InputErrorModal";

const tableHeaderItem = [
  { title: "Player Name", id: 1 },
  { title: " Status", id: 2 },
  { title: "Antime Goal", id: 3 },
  { title: " Correction", id: 4 },
  {},
];

export default function ModalTable(props) {
  const { tableItem, tableItemHome, tablePlayersData, tablePlayerHome } = props;

  const league_id = useSelector((state) => state.searching.league_id);
  const selectedMatchId = useSelector(
    (state) => state.searching.selectedMatchId
  );

  const dispatch = useDispatch();

  const [status, setStatus] = useState({
    status: "",
  });
  const [inputValue, setInputValue] = useState({
    manual_correction: null,
  });
  const [errorModal, setErrorModal] = useState(false);

  const handleInputValue = (e) => {
    const { value, name } = e.target;

    setInputValue({ [name]: { manual_correction: Number(value) } });
  };

  const handleSelectedStatus = (e) => {
    const { value } = e.target;

    setStatus({ status: value });
  };

  const inputData = Object.values(inputValue);

  const data = { ...status, ...inputData[0] };

  const sendChangedData = (id) => {
    if (!Number.isInteger(data.manual_correction)) {
      dispatch(editPlayer(league_id, selectedMatchId, id, data));
    } else {
      setErrorModal(!errorModal);
    }
  };

  const toggleErrorModal=()=>{
    setErrorModal(!errorModal);
  }

  return (
    <>
      {errorModal && <InputErrorModal toggleErrorModal={toggleErrorModal} />}

      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <table className={styles.table}>
          <thead style={{ backgroundColor: "#43464B" }}>
            <tr style={{ width: "33%" }}>
              {tableHeaderItem.map((item) => {
                return (
                  <th className={styles.tableHeader} key={item.id}>
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableItem.map((el, idx) => {
              return (
                <tr key={idx}>
                  <td className={styles.tableCol}>{el.name}</td>

                  <td className={styles.tableCol}>
                    <select
                      onChange={handleSelectedStatus}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <option>{el.status}</option>
                      <option value="Approved">Approved</option>
                      <option value="Not approved">Not Approved</option>
                      <option value="Suspended">Suspended</option>

                    </select>
                  </td>
                  <td className={styles.tableColCor}>{el.price_1}</td>

                  <td style={{ width: "100%", height: "100%" }}>
                    <input
                      name={tablePlayerHome[idx]}
                      className={styles.tableColCorrection}
                      type="number"
                      onChange={handleInputValue}
                    />
                  </td>

                  <td>
                    <button
                      className={styles.btnEdit}
                      onClick={() => sendChangedData(tablePlayersData[idx])}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        <table className={styles.table}>
          <thead style={{ backgroundColor: "#43464B" }}>
            <tr style={{ width: "33%" }}>
              {tableHeaderItem.map((item) => {
                return (
                  <th className={styles.tableHeader} key={item.id}>
                    {item.title}
                  </th>
                );
              })}
            </tr>
          </thead>

          <tbody>
            {tableItemHome.map((el, idx) => {
              return (
                <tr key={idx}>
                  <td className={styles.tableCol}>{el.name}</td>

                  <td className={styles.tableCol}>
                    <select
                      onChange={handleSelectedStatus}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <option>{el.status}</option>
                      <option value="Approved">Approved</option>
                      <option value="Not approved">Not Approved</option>
                      <option value="Suspended">Suspended</option>

                    </select>
                  </td>
                  <td className={styles.tableColCor}>{el.price_1}</td>
                  <td style={{ width: "100%", height: "100%" }}>
                    <input
                      name={tablePlayersData[idx]}
                      onChange={handleInputValue}
                      className={styles.tableColCorrection}
                      type="number"
                    />
                  </td>
                  <td>
                    <button
                      className={styles.btnEdit}
                      onClick={() => sendChangedData(tablePlayerHome[idx])}
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </>
  );
}
