
import * as actionType from "./Auth.actionsTypes";

const defaultState = {
  user: {},
  users:[],

};

export default function AuthReducer(state = defaultState, action) {
  switch (action.type) {
    case actionType.LOGIN_SUCCESS: 
      return { ...state, user: action.payload };

    case actionType.UPDATE_TOKEN:
      return {
        ...state,
        user: { ...state.user, access_token: action.payload },
      };
        case actionType.GET_USERS:
          return {
            ...state,
            users:action.payload,
          };
    case actionType.LOGOUT:
      return defaultState;
    default:
      return state;
  }
}
