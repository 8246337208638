import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { Login } from "../../Redux/Auth/Auth.actions";
import { useDispatch } from "react-redux";
import styles from "./loginPage.module.scss";
import { Link, useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-solid-svg-icons";

function LoginPage() {
  const dispatch = useDispatch();
  const history = useHistory();

  const [values, setValues] = useState({ login: "", password: "" });

  const [errors, setErrors] = useState({ login: null, password: null });

  const handleSubmit = async () => {
    const { login, password } = values;

    setErrors({
      login: login ? null : "Login is required",
      password: password ? null : "Password is required",
    });

    if (login && password) {
      const isOk = await dispatch(Login(values));
      if (isOk) {
        history.push("/dataBrokersPage");
      }
    }

  };

  const handleChange = ({ target: { name, value } }) => {
    setValues({ ...values, [name]: value });

    setErrors({ ...errors, [name]: null });
  };

  const keyPress = (event) => {
    if (event.key === "Enter") {
      handleSubmit();
    }
  };

  return (
    <div className={styles.main}>
      <div className={styles.loginPageLogoWrapper}>
        <Link to="/">
          <div className={styles.loginPageLogo}>SportDataBrokers</div>
        </Link>
      </div>

      <div className={styles.loginBlock}>
        <div className={styles.loginContainer}>
          <Form className={styles.formBlock}>
            <h3 className={styles.heading}> Login </h3>
            <Form.Group>
              <Form.Control
                className={errors.login ? styles.invalid : ""}
                type="text"
                name="login"
                placeholder=" Login"
                value={values.email}
                onChange={handleChange}
              />
              {<Form.Text className="text-danger">{errors.login}</Form.Text>}
            </Form.Group>

            <Form.Group>
              <Form.Control
                className={errors.password ? styles.invalid : ""}
                type="password"
                placeholder="Password"
                value={values.password}
                onChange={handleChange}
                name="password"
                onKeyPress={keyPress}
              />
              {<Form.Text className="text-danger">{errors.password}</Form.Text>}
            </Form.Group>

            <div className="text-center">
              <Button variant="danger" onClick={handleSubmit}>
                Login
              </Button>
            </div>
          </Form>
        </div>

      

    </div>
    <div className={styles.loginPageFooterWrapper}>
        <div className={styles.loginPageFooter}>
          Contact Us - All rights reserved SportDataBrokers{" "}
          <span>
            <FontAwesomeIcon icon={faCopyright} />
          </span>{" "}
          2021 business@sportdatabrokers.com
        </div>
      </div>

    </div>
  );
}

export default LoginPage;
