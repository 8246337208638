import React, { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styles from "./searchingSection.module.scss";
import {
  getLeagues,
  getMatches,
  showTable,
  getSelectedMatchId,
  getLigaId,
  getSelectedSport
} from "../../Redux/SearchingSection/SearchingSection.actions";
import Select from "react-select";

function SearchingSection() {
  const dispatch = useDispatch();
  // const userLeagues = useSelector((state) => state.searching.userLeagues);
  const leagues = useSelector((state) => state.searching.leagues);
  const sport =  ((state) => state.searching.sport);
  const matches = useSelector((state) => state.searching.matches);
  // const selectedSports=useSelector((state) => state.searching.selectedSport);
  // const selectedLigaId=useSelector((state) => state.searching.selectedLigaId);



  const [selectedLeague, setSelectedLeague] = useState({
    value: "",
    label: "Leagues",
  });
  const [selectedMatches, setSelectedMatches] = useState({
    value: "",
    label: "Match",
  });
  const [selectedSport, setSelectedSport] = useState({
    value: "",
    label: "Sport",
  });

  const [selectedDate, setSelectedDate] = useState({
    value: "",
    label: "Date",
  });

  const sportName = [];
  const arr = Object.values(leagues).map((item) => sportName.push(item.sport));
  const uniqueSport = sportName.filter((v, i, a) => a.indexOf(v) === i);

  const ligaKey = Object.keys(leagues);
  const ligaValue = Object.values(leagues);
  const key = [];
  const value = [];
  const data = ligaValue.filter((item, idx) => {
    if (item.sport === selectedSport.value) {
      value.push(item.name);
      key.push(ligaKey[idx]);
    }
    return false;
  });

  const ligaSuitble = [];
  for (let i = 0; i < value.length; i++) {
    const ligaData = { nam: value[i], id: key[i] };
    ligaSuitble.push(ligaData);
  }

  const match = Object.values(matches);
  const matchId = Object.keys(matches);

  const handleChangeLeagues = (e) => {
    setSelectedLeague({
      ...selectedLeague,
      value: e.value,
      label: e.label,
    });

    // setSelectedMatches({
    //   value: "",
    //   label: "Match",
    // });

    const ligaId = e.value;
    dispatch(getLigaId(ligaId));

    dispatch(getMatches(ligaId, sport));
  };

  const handleChangeSport = (e) => {

    dispatch(getSelectedSport( e.value));
    setSelectedSport({
      ...selectedSport,
      value: e.value,
      label: e.label,
    });
  };
  // console.log(!!selectedSports);

  const handleChangeMatches = (e) => {
    const value = e.value;
    dispatch(getSelectedMatchId(matchId[value]));
    setSelectedMatches({
      ...selectedMatches,
      value: e.value,
      label: e.label,
    });
  };

  const handleChangeDate = (e) => {
    setSelectedDate({
      ...selectedDate,
      value: e.value,
      label: e.label,
    });
  };

  const showTables = () => {
    dispatch(showTable());

    // setSelectedLeague({
    //   ...selectedLeague,
    //   value: '',
    //   label: 'Leagues',
    // });

    // setSelectedMatches({
    //   value: "",
    //   label: "Match",
    // });
    // setSelectedSport({
    //   ...selectedSport,
    //   value: '',
    //   label: 'Sport',
    // });
  };

  useEffect(() => {
    dispatch(getLeagues());

  }, [dispatch]);

  const matchOptions =
    match.length &&
    match.map((item, idx) => ({
      value: idx,
      label: ` ${item.home_name}- ${item.away_name}`,
    }));

  const leaguesOptions =
    ligaSuitble.length &&
    ligaSuitble.map((item) => ({
      value: item.id,
      label: item.nam,
    }));

  const sportOptions =
    uniqueSport.length &&
    uniqueSport.map((item) => ({
      value: item,
      label: item,
    }));

  const dateOptions = [{ value: "Date", label: "data", id: 1 }];

  return (
    <div className={styles.searchingSectionBlock}>
      <div className={styles.searchingSectionBlockContainer}>
        <div className={styles.wrapper}>
          <div className={styles.selectBoxWrapperFirst}>
            <div style={{ width: "49%" }}>
              <Select
                value={selectedDate}
                onChange={handleChangeDate}
                options={dateOptions}
                isSearchable={false}
              />
            </div>
            <div style={{ width: "49%" }}>
              <Select
                value={selectedSport}
                onChange={handleChangeSport}
                options={sportOptions}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <div className={styles.selectBoxWrapperLast}>
            <div style={{ width: "49%" }}>
              <Select
                value={selectedLeague}
                onChange={handleChangeLeagues}
                options={leaguesOptions}
                isSearchable={false}
              />
            </div>

            <div style={{ width: "49%" }}>
              <Select
                value={selectedMatches}
                onChange={handleChangeMatches}
                options={matchOptions}
                isSearchable={false}
              />
            </div>
          </div>
        </div>
        <div className={styles.wrapper}>
          <button
            className={styles.applayButton}
            onClick={() => showTables()}
            disabled={selectedMatches.value === ""}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchingSection;
