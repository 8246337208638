import DataBrokersPage from "../pages/DataBrokersPage/DataBrokersPage";
import HomePage from "../pages/HomePage/HomePage";
import Contact from "../components/Contact/Contact";
import Services from "../components/Services/Services";
import LoginPage from "../pages/LoginPage/LoginPage";
import { Switch } from "react-router-dom";
import AuthRoute from "../components/AuthRoute";
import "bootstrap/dist/css/bootstrap.min.css";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../Redux/Auth/Auth.actions";
import { getUser } from "../Redux/SearchingSection/SearchingSection.actions";

function App() {
  const dispatch = useDispatch();
  const accessToken = useSelector(
    (state) => state.authReducer.user.access_token
  );

  useEffect(() => {
    if (accessToken) {
      dispatch(getUser());
      dispatch(getUsers());
    }
  }, [dispatch, accessToken]);

  return (
    <div>
      <Switch>
        <AuthRoute exact path="/" component={HomePage} />
        <AuthRoute
          path="/dataBrokersPage"
          component={DataBrokersPage}
          type="private"
        />
        <AuthRoute path="/services" component={Services} />
        <AuthRoute path="/contact" component={Contact} />
        <AuthRoute path="/login" component={LoginPage} type="public" />
      </Switch>
    </div>
  );
}

export default App;
