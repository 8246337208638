import React from "react";
import styles from "./inputErrorModal.module.scss";

export default function InputErrorModal({ toggleErrorModal }) {
  return (
    <div className={styles.errorModal}>
      <div style={{marginTop:'20px',textAlign:'center'}}>
        
        <p style={{ color: "red" }}>
          Please fill in valid float number new correction fild
        </p>
      </div>
      <div className={styles.errorModalBtnBox}>
        <button className={styles.errorModalBtn} onClick={() => toggleErrorModal()}>Close</button>
      </div>
    </div>
  );
}
