import React from "react";
import Header from "../Header/Header";
import Contact from "../Contact/Contact";
import Services from "../Services/Services";
import HomeNavigation from "../HomeNavigation/HomeNavigation";

// import styles from './home.module.scss'

export default function Home() {
  return (
    <div >
      <HomeNavigation/>
      <Header />
      <Services />
      <Contact />
    </div>
  );
}
