import React from "react";
import styles from "./TableSectionModal.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useSelector } from "react-redux";
import ModalTable from "../ModalTable/ModalTable";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { getMatches } from "../../Redux/SearchingSection/SearchingSection.actions";

export default function TableSectionModal(props) {
  const { onClose } = props;

  const dispatch = useDispatch();
  const matches = useSelector((state) => state.searching.matches);
  const selectedSports = useSelector((state) => state.searching.selectedSport);
  const selectedLigaId = useSelector((state) => state.searching.selectedLigaId);
  const selectedMatchId = useSelector(
    (state) => state.searching.selectedMatchId
  );

  const selectedData = matches[selectedMatchId];
  const { away, home } = selectedData?.calculated || {};

  const awayArrData = Object.values(away);
  const homeArrData = Object.values(home);

  const awayPlayerData = Object.keys(away);
  const homePlayerData = Object.keys(home);

  const saveChangeData = () => {
   
    if(!!selectedSports&&!!selectedLigaId&&!!selectedMatchId){
      dispatch(getMatches(selectedLigaId));
      onClose()
    }

  };

  return (
    <div className={styles.modalBlock}>
      <div className={styles.closeIconBlock}>
        <FontAwesomeIcon icon={faTimes} onClick={onClose} />
      </div>

      <div>
        {!!Object.keys(matches).length && (
          <div className={styles.headerTitlelWrapper}>
            <div className={styles.headerTitlelContainer}>
              <div className={styles.teamName}>{selectedData.away_name}</div>

              <div className={styles.teamName}>{selectedData.home_name}</div>
            </div>
            <div className={styles.tableWrapper}>
              <ModalTable
                tableItem={awayArrData}
                tableItemHome={homeArrData}
                tablePlayersData={awayPlayerData}
                tablePlayerHome={homePlayerData}
              />
            </div>
            <div className={styles.footerBtnBlockWrapper}>
            <Button style={{marginRight:'10px'}} variant="success" onClick={saveChangeData}>
                Save
              </Button>
              <Button variant="secondary" onClick={onClose}>
                Cancel
              </Button>

            </div>
          </div>
        )}
      </div>
    </div>
  );
}
