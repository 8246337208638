import React from "react";
import "./header.scss";
import Info from "../Info/Info";

export default function Header() {
  return (
    <div className="headerBlock">
      <div className="headerBlockContainer">
        <div className="header-text-content">
          <div>
            <div className="title">ODDS, CONTENT and MORE</div>
          </div>
        </div>

        <Info />
      </div>
    </div>
  );
}
