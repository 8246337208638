import { Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { history } from "../helpers";

export default function AuthRoute({ path, component: Component, type }) {
  const user = useSelector((state) => state.authReducer.user);

  return (
    <Route
    history={history}
      path={path}
      render={(props) => {
        if (user?.access_token && type === "public") {
          return <Redirect to="/dataBrokersPage" />;
        }
        if (!user?.access_token && type === "private") {
          return <Redirect to="/login" />;
        }
    
        return <Component {...props} />;
      }}
    />
  );
}
