import React from "react";
import styles from "./info.module.scss";
import { Container, Row, Col } from "react-bootstrap";

export default function Info() {
  return (
    <Container>
      <Row>
        <Col>
          <div className={styles.infoBlock}>
            <div className={styles.infoBlockLeft}>
              <div
                style={{
                  fontSize: "22px",
                  fontWeight: "700",
                  marginTop: "15px",
                }}
              >
                {" "}
                Experience & Know How
              </div>
              <div style={{ marginTop: "25px" }}>
                Over 15 year in the industry
              </div>
              <div>
                Deep knowledge of football, ice-hockey, volleyball and other
                sports
              </div>
              <div>Focus on unique add-ons to make your offer special</div>
              <div>Unique and niche tailor-made products</div>
            </div>
            <div className={styles.infoBlockRight}>
              <div></div>
              <div
                style={{
                  fontSize: "25px",
                  fontWeight: "700",
                  marginTop: "15px",
                }}
              >
                Technology
              </div>
              <div style={{ marginTop: "20px" }}>
                Machine Learning and Artificial Intelligence driven
              </div>
              <div>Flexible integration</div>
              <div>Best price on the market</div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
