import * as actionType from "./SearchingSection.actionsType";

const defaultState = {

  leagues: {},
  sport: [],
  matches:{},
  show:false,
  userLeagues:[],
  selectedMatchId:null,
  loading:false,
  modifyPlayer:[],
  league_id:null,
  userMeData:null,
  selectedLigaId:null,
  selectedSport:null
};

export default function SearchingSectionReducer(state = defaultState, action) {
  switch (action.type) {

    case actionType.GET_LEAGUES:
      return {
        ...state,
        leagues: action.payload,
      };
    case actionType.GET_SPORT:
      return {
        ...state,
        matches:action.payload.matches,
        league_id:action.payload.league_id,
        // selectedMatchId:null
      };
      case actionType.SHOW_TABLE:
        return {
          ...state,
         show:!state.show
        };
        case actionType.GET_USER_ME:
          return {
            ...state,
            userLeagues:action.payload.leagues,
            userMeData:action.payload,
            loading:false
            
          };
          case actionType.GET_SELECTED_MATCH_ID:
          return {
            ...state,
            selectedMatchId:action.payload
          };

          case actionType.GET_MODIFY_PLAYER:
            return {
              ...state,
              modifyPlayer:[...state.modifyPlayer, action.payload]
            };
            case actionType.CLEAR_MATCH_ID:
              return {
                ...state,
                show:false, 
                // selectedMatchId:null
              };
              case actionType.GET_LIGA_ID:
                return {
                  ...state,
                  selectedLigaId:action.payload
                };
                case actionType.GET_SELECTED_SPORT:
                  return {
                    ...state,
                    selectedSport:action.payload
                  };
    default:
      return {
        ...state,
      };
  }
  
}