import { applyMiddleware, createStore } from "redux";
import thunk from "redux-thunk";
import rootReducer from "./root.reducer";
import { persistStore } from "redux-persist";
import { composeWithDevTools } from "redux-devtools-extension";

const middleware = applyMiddleware(thunk);

export const store = createStore(rootReducer, composeWithDevTools(middleware));
export const persistor = persistStore(store);
