import { combineReducers } from "redux";
import SearchingSectionReducer from "../Redux/SearchingSection/SearchingSection.reducer";
import AuthReducer from "../Redux/Auth/Auth.reducer";

import { persistReducer } from "redux-persist";

import { authPersistConfig } from "./persist.config";

const rootReducer = combineReducers({
  searching: SearchingSectionReducer,
  authReducer: persistReducer(authPersistConfig, AuthReducer),
});

export default rootReducer;
