import React from "react";
import styles from "./FaceTableSection.module.scss";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";

export default function FaceTableSection() {
  return (
    <div className={styles.tableSectionBlock}>
      <div className={styles.tableSectionBlockHeader}>
        <div className={styles.tableSectionBlockHeaderContainer}>
          <div
            style={{
              width: "35%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                backgroundColor: "silver",
              }}
            ></span>
            <span>German Del</span>
            <span>/</span>
            <span>Allemagne Liga</span>
          </div>
          <div className={styles.btnWrapper}>
            <Button variant="danger" className={styles.btn}>
              <span style={{ marginRight: "10px", fontWeight: "700" }}>
                Download
              </span>
              <FontAwesomeIcon icon={faCloudDownloadAlt} />
            </Button>

            <Button variant="warning" className={styles.btn}>
              <span style={{ marginRight: "10px", fontWeight: "700" }}>
                Edit
              </span>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
          </div>
        </div>
      </div>

      <div className={styles.headerTitlelWrapper}>
        <div className={styles.headerTitlelContainer}>
          <div className={styles.teamName}>Rochester Americans</div>
          <div>
            <div
              style={{
                fontSize: "13px",
                textAlign: "center",
                fontFamily: "Lato",
                color: "#463d3dde",
              }}
            >
              Game Start Today
            </div>
            <div
              style={{
                textAlign: "center",
                fontSize: "35px",
                fontWeight: "bold",
              }}
            >
              6:18
            </div>
          </div>
          <div className={styles.teamName}>Syracuse Crunch</div>
        </div>
      </div>
      <div
        style={{
          width: "100%",
          marginTop: "20px",
        }}
      >
        <button className={styles.statisticBtn}><span style={{ marginLeft: "6px" }}>Statistics</span></button>
      </div>
    </div>
  );
}
