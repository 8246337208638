import React, { useEffect, useState } from "react";
import styles from "./TableSection.module.scss";
import Table from "../Table/Table";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import TableSectionModal from "../TableSectionModal/TableSectionModal";
import {
  csvDownloader,
  showTable,
} from "../../Redux/SearchingSection/SearchingSection.actions";

export default function TableSection(props) {
  const [modal, setModal] = useState(false);
  const dispatch = useDispatch();

  const matches = useSelector((state) => state.searching.matches);

  const selectedMatchId = useSelector(
    (state) => state.searching.selectedMatchId
  );
  
  const userMeData = useSelector((state) => state.searching.userMeData);
  const ligaId = useSelector((state) => state.searching.selectedLigaId);

  if (!!selectedMatchId) {
    var selectedData = matches[selectedMatchId];
    var { away, home } = selectedData?.calculated || {};

    var awayArrData = Object.values(away);
    var homeArrData = Object.values(home);
  }

  const toggleModal = () => {
    setModal(!modal);
  };

  const csvDownload = () => {
    dispatch(csvDownloader(ligaId, selectedMatchId));
  };

  useEffect(() => {
    return () => {
      dispatch(showTable());
    };
  }, []);

  return (
    <div className={styles.tableSectionBlock}>
      <div style={{ position: "relative" }}>
        {!!Object.keys(matches).length && (
          <div>
            <div className={styles.tableSectionBlockHeader}>
              <div className={styles.tableSectionBlockHeaderContainer}>
                <div
                  style={{
                    width: "35%",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <span
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      backgroundColor: "silver",
                    }}
                  ></span>
                  <span>{selectedData.champ_name}</span>
                  <span>/</span>
                  <span>Allemagne Liga</span>
                </div>
                <div className={styles.btnWrapper}>
                  <Button
                    variant="danger"
                    className={styles.btn}
                    onClick={csvDownload}
                  >
                    <span style={{ marginRight: "10px", fontWeight: "700" }}>
                      Download
                    </span>
                    <FontAwesomeIcon icon={faCloudDownloadAlt} />
                  </Button>

                  {userMeData?.role === "admin" && (
                    <Button
                      variant="warning"
                      className={styles.btn}
                      onClick={toggleModal}
                    >
                      <span style={{ marginRight: "10px", fontWeight: "700" }}>
                        Edit
                      </span>
                      <FontAwesomeIcon icon={faEdit} />
                    </Button>
                  )}
                </div>
              </div>
            </div>

            <div className={styles.headerTitlelWrapper}>
              <div className={styles.headerTitlelContainer}>
                <div className={styles.teamName}>{selectedData.away_name}</div>
                <div>
                  <div
                    style={{
                      fontSize: "13px",
                      textAlign: "center",
                      fontFamily: "Lato",
                      color: "#463d3dde",
                    }}
                  >
                    Game Start Today
                  </div>
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "35px",
                      fontWeight: "bold",
                    }}
                  >
                    {moment.utc(selectedData.start_uts).format(" h:mm")}
                  </div>
                </div>
                <div className={styles.teamName}>{selectedData.home_name}</div>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                marginTop: "20px",
              }}
            >
              <button className={styles.statisticBtn}>
                <span style={{ marginLeft: "6px" }}>Statistics</span>
              </button>
            </div>

            <div className={styles.tableContainer}>
              <Table tableItem={awayArrData} />
              <Table tableItem={homeArrData} />
            </div>
          </div>
        )}
        {modal && <TableSectionModal onClose={toggleModal} />}
      </div>
    </div>
  );
}
