import * as actionType from "./SearchingSection.actionsType";
import axios from "axios";

const blobAxios = axios.create({
  headers: { "Content-Type": "text/plain" },
  responseType: "blob",
});

const leagues = (data) => ({
  type: actionType.GET_LEAGUES,
  payload: data,
});

const getMatchesData = (matches, league_id) => ({
  type: actionType.GET_SPORT,
  payload: { matches, league_id },
});

const modifyPlayer = (data) => ({
  type: actionType.GET_MODIFY_PLAYER,
  payload: data,
});

export const getLeagues = () => async (dispatch) => {
  try {
    const res = await axios.get(`leagues`);
    dispatch(leagues(res.data));
  } catch (error) {
    console.log(error);
  }
};
export const getMatches = (league_id) => async (dispatch) => {
  try {
    const res = await axios.get(`leagues/${league_id}/matches`);
    const matches = res.data.matches;
    console.log(matches);

    dispatch(getMatchesData(matches, league_id));
  } catch (error) {
    console.log(error);
  }
};

export const showTable = () => ({
  type: actionType.SHOW_TABLE,
});

const UserMe = (data) => ({
  type: actionType.GET_USER_ME,
  payload: data,
});

export const getSelectedMatchId = (data) => ({
  type: actionType.GET_SELECTED_MATCH_ID,
  payload: data,
});

export const getUser = () => async (dispatch) => {
  try {
    const res = await axios.get(`auth/me`);
    dispatch(UserMe(res.data.me));
  } catch (error) {
    console.log(error);
  }
};

export const clearMatchId = () => ({
  type: actionType.CLEAR_MATCH_ID,
});

export const getLigaId = (ligaId) => ({
  type: actionType.GET_LIGA_ID,
  payload: ligaId,
});
export const getSelectedSport = (sport) => ({
  type: actionType.GET_SELECTED_SPORT,
  payload: sport,
});

export const editPlayer =
  (league_id, match_id, player_id, changeItem) => async (dispatch) => {
    try {
      const res = await axios.patch(
        `leagues/${league_id}/matches/${match_id}/players/${player_id}`,
        changeItem
      );

      dispatch(modifyPlayer(res.modified));
    } catch (error) {
      console.log(error);
    }
  };

export const csvDownloader = (league_id, match_id) => async (dispatch) => {
  try {
    const res = await blobAxios.get(
      `leagues/${league_id}/matches/${match_id}/data.csv`
    );
    const url = window.URL.createObjectURL(new Blob([res.data]));
    var link = document.createElement("a");
    link.setAttribute("download", "data.csv");
    link.href = url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    console.log(res);
  } catch (error) {
    console.log(error);
  }
};
