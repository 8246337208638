import React from "react";
import styles from "./table.module.scss";
import moment from "moment";

const tableHeaderItem = [
  { title: "Player Name", id: 1 },
  { title: "Market", id: 2 },
  { title: "Line", id: 3 },
  { title: "Antime Goal", id: 4 },
  { title: "Status", id: 5 },
  { title: "Last Update", id: 6 },
];

export default function Table(props) {
  const { tableItem } = props;

  return (
    <div style={{ width: "48%", margin: "30px "}}>
      <table className={styles.table}>
        <thead style={{backgroundColor:'#43464B'}}>
          <tr>
            {tableHeaderItem.map((item) => {
              return (
                <th className={styles.tableHeader} key={item.id}>
                  {item.title}
                </th>
              );
            })}
          </tr>
        </thead>

        <tbody>
          {tableItem.map((el, idx) => {
            return (
              <tr key={idx}>
                <td className={styles.tableCol}>{el.name}</td>
                <td className={styles.tableRow}>{el.goals}</td>
                <td className={styles.tableRow}>0.5</td>
                <td className={styles.tableRow}>{el.price_1}</td>
                <td className={styles.tableRow}>{el.status}</td>
                <td className={styles.tableRow}>
                  {moment.utc(el.parsing_uts * 1000).format("L h:mm")}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
