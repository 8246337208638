import axios from "axios";
import { store } from "../Redux/store";
import { refreshToken, logout } from "../Redux/Auth/Auth.actions";

console.log(process.env);

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

axios.interceptors.request.use((request) => {
  const reduxStore = store.getState();
  const {
    user: { access_token },
  } = reduxStore.authReducer;

  if (access_token) {
    request.headers["Authorization"] = `Bearer ${access_token}`;
  }

  return request;
});

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const reduxStore = store.getState();

    const {
      user: { access_token, refresh_token },
    } = reduxStore.authReducer;

    const errorData = error?.response?.data;
    if (errorData?.exception === "Unauthorized" && access_token) {
      store.dispatch(refreshToken(refresh_token));
      store.dispatch(logout(refresh_token));
    }
    return Promise.reject(error);
  }
);

export default axios;
