import React from "react";
import styles from "./services.module.scss";
// import Info from '../Info/Info'
import { Container, Row, Col } from "react-bootstrap";
import { ReactComponent as Checked } from "../../assets/icon/checked.svg";

const serviceItems = [
  {
    title: "ODDS PRODUCTION",
    text:
      "We focus on unique, localized, tailor-made special solutions for sportsbooks across the globe. Machine-learning technology makes our products elegant, easy-to-integrate and affordable for everyone.",
  },
  {
    title: "TRADING",
    text:
      "We help to establish or outsource your personal or company sports trading solutions and risk management.",
  },
  {
    title: "LOCALIZATION",
    text:
      "We localize global content using best modern practices and we make your local content valuable in unpredictable other locations.",
  },
  {
    title: "AUTOMATED CONTENT",
    text:
      "SDB offers solutions for content automatization for sport, sports betting sites and social media.",
  },
  {
    title: "AMBASSADORING",
    text:
      "We help to find trustable persons in the world of sport to boost your content.",
  },
  {
    title: "AUDIT&CONSULTING",
    text:
      "Operational audit and consulting for your sportsbook or sport-content product.",
  },
];

const servicesFooterItems = [
  {
    title: "Player Props Feed",
    paragraf:
      "Player Props product will enable your customers choose their player, the statistic and a line to place a bet or add it to a multiple bet",
  },

  {
    title: "Industry Leading Coverag",
    paragraf: `Soccer – All Major Leagues & Cups
  US Sports – Full Coverage of NFL/NBA/MLB/NHL
  Touchdowns; Passing/Receiving/Rushing Yards etc.`,
  },

  {
    title: "Key Features",
    paragraf: `Customer Configurable Lines
  Cash Out
  In-Play​`,
  },

  {
    title: "Ready Made Multiples/Prepackaged Parlays",
    paragraf:
      "It is an ideal solution for operators looking to power social media based bet requests with features including Cash Out and In Play pricing",
  },
];

export default function Services() {
  return (
    <div className={styles.servicesBlock} id="services-section">
      <Container>
        <Row>
          <Col>
            {/* <Info/> */}
            <div style={{marginTop:'250px'}}>
              <div className={styles.serviceTitle}>Our Services</div>

              <ul>
                {serviceItems.map((item, index) => {
                  return (
                    <li key={index} className={styles.liStyle}>
                      <div style={{ width: "100%", textAlign: "center" }}>
                        <Checked />
                      </div>
                      <p style={{ color: "white", textAlign: "center" }}>
                        {item.title}
                      </p>
                      <p style={{ color: "white", textAlign: "center" }}>
                        {item.text}
                      </p>
                    </li>
                  );
                })}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>

      <div className={styles.servicesFooterBlock}>
        <Container>
          <Row>
            <Col>
              <div
                style={{ display: "flex", flexWrap: "wrap", marginTop: "65px" }}
              >
                {servicesFooterItems.map((item, index) => {
                  return (
                    <div key ={index} className={styles.serviceItemBlock}>
                      <div className={styles.header}> {item.title}</div>
                      <div className={styles.body}>{item.paragraf}</div>
                    </div>
                  );
                })}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
}
