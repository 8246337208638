import React from "react";
import styles from "./contact.module.scss";
import { useState } from "react";
import { Button, Container, Row, Col, Form } from "react-bootstrap";

const requiredErrorMessage='Field is required';

export default function Contact() {
  const [values, setValues] = useState({
    name: "",
    email: "", 
    message: "",
  });

  const [errors, setErrors] = useState({
    name: null,
    email: null,
    message: null,
  });
  const handleChange = ({ target: { name, value } }) => {

    if(!value){
      setErrors({
        ...errors,
        [name]:requiredErrorMessage ,
      });

    }else{
      setErrors({
        ...errors,
        [name]: null,
      });
    }

    if(name==="email"&&value){
      const emailReg=/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/
      if(!emailReg.test(value)){
        setErrors({
          ...errors,
          email: "Invalid email",
        });
      }
    }
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleSbmit=()=>{
    const errorsArr=Object.values(errors);
    const errorsExist=!errorsArr.every((el)=>el===null)

    const valuesArr=Object.values(values);
    const valuesExist=!valuesArr.some((el)=>el==='')

    // if(valuesExist&&!errorsExist) {

   
    // }

    if(!valuesExist&&!errorsExist){
      setErrors({
        name: requiredErrorMessage,
        email: requiredErrorMessage,
        message:requiredErrorMessage,
      });

    }
  }

  return (
    <div className={styles.contactBlock} id="contact-section">
       <Container>
      <Row>
        <Col xs={7}>
          <Form className="mt-4">
            <h2 style={{color:"#fff"}}>Contact Us</h2>
            <Form.Group style={{marginTop:'30px'}}>
              <Form.Control
              className={errors.name?styles.invalid: ''}
                type="text"
                placeholder="Enter your  name"
                name="name"
                value={values.name}
                onChange={handleChange}
              />

              <Form.Text className="text-danger">
            {errors.name}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Control
               className={errors.email?styles.invalid: ''}
                type="email"
                placeholder="Enter email"
                name="email"
                value={values.email}
                onChange={handleChange}
              />
              <Form.Text className="text-danger">
              {errors.email}
              </Form.Text>
            </Form.Group>
            <Form.Group>
              <Form.Control
              className={errors.message?styles.invalid: ''}
                as="textarea"
                placeholder="Enter your massege"
                row={5}
                name="message"
                value={values.message}
                onChange={handleChange}
              />
              <Form.Text className="text-danger">
              {errors.message}
              </Form.Text>
            </Form.Group>

            <Button 
            variant="primary"
            onClick={handleSbmit}
            >
              SEND MESSAGE
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
    </div>
  );
}





