import React from "react";
import { Link } from "react-router-dom";
import styles from "./navigation.module.scss";

const navListItem = [
  { id: 1, name: "PlayerProps" },
  { id: 2, name: "OPTI-Totals" },
  { id: 3, name: "RoboBet" },
];

export default function Navigation() {
  return (
    <div className={styles.navigationBlock}>
      <Link to='/'>
        <div className={styles.navigationlogo}>SportDataBrokers </div>
      </Link>

      <nav className={styles.navigationNav}>
        <ul className={styles.navigationUl}>
          {navListItem.map((item) => {
            return (
              <li className={styles.navigationLi} key={item.id}>
                {item.name}
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}
