import React from "react";
import { useSelector } from "react-redux";
import Navigation from "../../components/Navigation/Navigation";
import SearchingSection from "../../components/SearchingSection/SearchingSection";
import TableSection from "../../components/TableSection/TableSection";
import FaceTableSection from "../../components/FaceTableSection/FaceTableSection";
import styles from "./dataBrokersPage.module.scss";

export default function DataBrokersPage() {
  // const selectedMatchId = useSelector(
  //   (state) => state.searching.selectedMatchId
  // );
  const loading = useSelector((state) => state.searching.loading);
  const show = useSelector((state) => state.searching.show);
  return (
    <div>
      {!loading ? (
        <div>
          <Navigation />
          <div className={styles.body}>
            <SearchingSection />
            {show ?  <TableSection />: <FaceTableSection />}
          </div>
        </div>
      ) : (
        <p>Loading......</p>
      )}
    </div>
  );
}
