import * as actionType from "./Auth.actionsTypes";
import axios from "axios";

export const logout = () => ({ type: actionType.LOGOUT });

const loginSuccess = (data) => ({
  type: actionType.LOGIN_SUCCESS,
  payload: data,
});

const updateToken = (token) => ({
  type: actionType.UPDATE_TOKEN,
  payload: token,
});

const Users = (data) => ({
  type: actionType.GET_USERS,
  payload: data,
});

export const Login = (form) => (dispatch) => {
  axios.post("auth", form).then((res) => dispatch(loginSuccess(res.data)));
};

export const refreshToken = (refreshToken) => async (dispatch) => {
  try {
    const res = await axios.post(`auth/refresh`, {
      refresh_token: refreshToken,
    });

    const token = res.data.access_token;
    dispatch(updateToken(token));
  } catch (error) {
    console.log(error);
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    const res = await axios.get(`users`);
    dispatch(Users(res.data));
  } catch (error) {
    console.log(error);
  }
};
