import React from 'react'
import { NavLink } from "react-router-dom";
import styles from './HomeNavigation.module.scss'
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../Redux/Auth/Auth.actions";




export default function HomeNavigation() {

    const dispatch = useDispatch();
    const user = useSelector((state) => state.authReducer.user);
    const handleNavClick = (event) => {
      if (event.target.name) {
        const element = document.getElementById(event.target.name);
        element.scrollIntoView({ behavior: "smooth" });
      }
    };
  
    const handleLogioutClick = () => {
      dispatch(logout());
    };
    return (
        <div  className={styles.headerBlockContainer}>
            
        <div className={styles.headerNavigation}>
          <div className={styles.headerLogo}>SportDataBrokers</div>
          <div className={styles.wrapper}>
            <nav>
              <ul>
                <li>
                  <NavLink
                    className={styles.navigationItem}
                    to="/"
                    name="services-section"
                    onClick={handleNavClick}
                  >
                    SERVICES
                  </NavLink>
                </li>
                <li>
                  <NavLink className={styles.navigationItem} to="/dataBrokersPage">
                    PRODUCT
                  </NavLink>
                </li>

                <li>
                  <NavLink
                    className={styles.navigationItem}
                    to="/"
                    name="contact-section"
                    onClick={handleNavClick}
                  >
                    CONTACT
                  </NavLink>
                </li>

                <li>
                  {user.access_token ? (
                    <NavLink
                      onClick={handleLogioutClick}
                      className={styles.navigationItem}
                      to="/login"
                    >
                      LOGOUT
                    </NavLink>
                  ) : (
                    <NavLink className={styles.navigationItem} to="/login">
                      LOGIN
                    </NavLink>
                  )}
                </li> 
              </ul>
            </nav>
          </div>
        </div> 
            
        </div>
    )
}
